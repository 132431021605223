import { JsonObject } from "type-fest";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtagPageView = (url: URL): void => {
  window.gtag(
    "config",
    process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    {
      page_path: url,
    }
  );
};

type GTagEvent = {
  name: string;
  payload: JsonObject;
};

export const gtagEvent = ({ name, payload }: GTagEvent): void => {
  window.gtag("event", name, payload);
};
